import React, { useId } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { addContactToKeap } from '@hook-point/api-client';
import { FaSpinner } from 'react-icons/fa';
import { FaCircleExclamation } from 'react-icons/fa6';
import { FUNCTIONS_BASE_URL, ROOT_DOMAIN } from '../../constants';
import { openTermsModal } from '../../stores/termsModalOpenStore';
import { useForm, useFormField } from '../../utils/forms';
import * as styles from './EmailInput.module.scss';
import InputField from './InputField';
import TextLink from './TextLink';

interface EmailInputProps {
  buttonReadyText: string;
  buttonSubmittingText: string;
  buttonSubmittedText: string;
  className?: string;
  onSubmit?: () => void;
}

const EmailInput = ({
  buttonReadyText,
  buttonSubmittedText,
  buttonSubmittingText,
  className,
  onSubmit,
}: EmailInputProps): React.ReactElement => {
  const fieldsByName = {
    email: useFormField<string>('', ['required', 'email']),
    termsAndConditions: useFormField<boolean>(false, ['required']),
  } as const;

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState, onFieldUnfocus } =
    useForm({
      fieldsByName,
      onSubmit: internalOnSubmit,
      translateFunction: key => {
        return {
          'form.required_field_error': 'Please fill in the field above',
          'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
          'form.network_error': 'Network failed to send your request.',
          'form.unknown_error': 'An unexpected error occured. Please try again later.',
          'form.success_message': 'Newsletter subscribed successfully!',
        }[key];
      },
    });

  async function internalOnSubmit() {
    const success = await addContactToKeap({
      rootDomain: ROOT_DOMAIN,
      formType: 'newsletter',
      email: fieldsByName.email.value!.trim(),
      acceptTheTerms: fieldsByName.termsAndConditions.value!,
      functionsBaseUrl: FUNCTIONS_BASE_URL,
    });
    if (success) {
      if (onSubmit) {
        onSubmit();
      }
    }
    return success;
  }

  const emailInputId = 'email-input-' + useId();
  const termsAndConditionsInputId = 'termsAndConditions-input-' + useId();

  return (
    <div className={clsx(styles.formContainer, className)}>
      <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
        <input type="hidden" name="newsletter" />
        <div className={styles.inputContainer}>
          <div className={clsx(styles.inputWrapper, fieldsByName.email.error && styles.error)}>
            <InputField
              className={clsx(styles.input)}
              type="email"
              id={emailInputId}
              name="email"
              helperTextClass={styles.helperText}
              placeholder="youremail@example.com"
              {...getFieldProps(fieldsByName.email)}
            />
            {!fieldsByName.email.error && (
              <div className={styles.formMessageContainer}>
                {renderFormMessage({
                  styles: {
                    formMessage: styles.formMessage,
                    formMessageSuccess: styles.formMessageSuccess,
                    formMessageError: styles.formMessageError,
                  },
                })}
              </div>
            )}
            <div
              className={clsx(
                styles.checkboxContainer,
                !!fieldsByName.termsAndConditions.error && styles.error,
              )}
            >
              <div className={styles.checkboxOptionContainer}>
                <label htmlFor={termsAndConditionsInputId} className={styles.checkboxLabel}>
                  <div className={styles.singleCheckboxText}>
                    <p>
                      I agree to the{' '}
                      <TextLink
                        styleOnly
                        onClick={e => {
                          e.preventDefault();
                          openTermsModal();
                        }}
                        className={styles.checkboxLink}
                      >
                        terms and conditions
                      </TextLink>
                    </p>
                  </div>
                  <input
                    className={clsx(styles.checkbox)}
                    type="checkbox"
                    name="termsAndConditions"
                    id={termsAndConditionsInputId}
                    checked={!!fieldsByName.termsAndConditions.value}
                    onChange={event => {
                      fieldsByName.termsAndConditions.setValue(event.target.checked);
                      fieldsByName.termsAndConditions.setError('');
                    }}
                    onBlur={() => onFieldUnfocus(fieldsByName.termsAndConditions)}
                  />
                  <span className={styles.checkmark} />
                </label>
              </div>
              <div
                className={!!fieldsByName.termsAndConditions.error ? styles.helperText : ''}
                style={{
                  display: !fieldsByName.termsAndConditions.error ? 'none' : undefined,
                }}
              >
                <FaCircleExclamation title={fieldsByName.termsAndConditions.error} />
              </div>
            </div>
          </div>
        </div>
        {renderSubmitButton({
          labels: {
            ready: buttonReadyText,
            submitting: buttonSubmittingText,
            submitted: buttonSubmittedText,
          },
          btnClasses: {
            common: styles.submitButton,
            ready: styles.formReady,
            submitting: styles.formSubmitting,
            submitted: styles.formSubmitted,
          },
          childrenBefore: submitState === 'submitting' && (
            <FaSpinner className={clsx(styles.buttonIcon, styles.buttonIconLoading)}></FaSpinner>
          ),
        })}
      </form>
    </div>
  );
};

export default EmailInput;
